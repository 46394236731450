import TAGSORT,{taglist} from "../class/tagsort";

const [ w, d, mq ] = [window, document, window.matchMedia("(min-width:900px)") ];

const itemTemplate =  `
  <ul class="service_list" :class="[ 'is-paged-' + paged ]">
    <li v-for="(article , ind) in pagedArticles">
      <figure>
        <img :src="article.thumb" :alt="article.title">
      </figure>
      <div class="text_wrap">
        <h3 class="ttl">
          <strong>{{ article.title.split('|')[0].trim() }}</strong>
          <small>{{ article.title.split('|')[1].trim() }}</small>
        </h3>
        <p class="text" v-if="article.body.length">{{ article.body }}</p>
        <div class="btn_wrap"><a class="text_arrow" :href="article.href">view more</a></div>
      </div>
    </li>
  </ul>
  `;


class taglist_custom extends taglist {
  constructor( opt, parent ) {
    super( opt, parent );
  }
  insertContainer ( tag = `<taglist />`) {
    const container = d.querySelector( this.opt.container || '.rwd002-container');
    // const pagination = d.querySelector( this.opt.pagination || '.rwd002-pagination');
    // const is_list = d.body.classList.contains('is--list');
    // const is_detail = d.body.classList.contains('is--detail');
    if( !container ) return false;

    // bottom
    // if( pagination ){
    //   pagination.insertAdjacentHTML('afterend', tag);
    // }else {
    //   container.insertAdjacentHTML('afterend', tag);
    // }
  }
}

class OPTIONS {
  constructor() {
    this.listBody();
    this.detailTtl();
  }

  listBody() {
    $(".service_list .text").each(function(index, element) {
      let bodyText = $(element).text();
      // 半角を削除
      let Replace = bodyText.replace(/[!-~\s]/g, '');
      // 全角の文字数をカウント
      let num = Replace.length;
      // 153文字より多いと…
      if(num > 153){
        let ReplaceMax = Replace.substring(0, 153);
        let overText = ReplaceMax += '…';
        $(this).text(overText);
      } else {
        $(this).text(Replace);
      }
    });
  }

  detailTtl() {
    const is_detail = d.body.classList.contains('is--detail');
    if( is_detail ){
      const enTtl = $('.rwd002-detail__title').text().split('|')[0];
      const jpTtl = $('.rwd002-detail__title').text().split('|')[1].trim();
      // 詳細タイトル上書き
      $('.content-head-banner h2 strong').text(enTtl);
      $('.content-head-banner h2 small').text(jpTtl);
      // パンくず追加
      $('.pan').append('<li>' + enTtl + '</li>');
    }
  }
}

const domReady = () => {
  
  const Tagsort = new TAGSORT({
    list: {
      itemTemplate,
      perpage: 18, // 一度に表示する記事数
    },
    taglist: {
      pagination: '.rwd002-pagination', // taglistを表示する基準の要素
    }
  });

  d.addEventListener('tagsort.beforeInit', e => {
    const TAGSORT = e.detail;
    Tagsort.taglist = new taglist_custom( TAGSORT.opt.taglist, TAGSORT );
  });

  d.addEventListener('tagsort.afterRender', e => {
    new OPTIONS();
  });

  // Tagsort.init.then( tagsort => {  })

  Tagsort.render();
};

export default function SERVICE () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}