const [ w, d ] = [window, document ];

const itemProf =  `
  <div class="rwd002-sitemap__contents">
    <h4 class="rwd002-sitemap__contents-title"><a href="/profile.php" title="店舗案内" >店舗案内</a></h4>     
    <ul class="rwd002-sitemap-category">
      <li class="rwd002-sitemap-category__item">
        <ul class="rwd002-sitemap-article">
          <li class="rwd002-sitemap-article__item"><a href="/about/?mode=detail&article=1" title="店舗案内">店舗案内</a></li>
        </ul>
      </li>
    </ul>
  </div>
  `;

export default function SITEMAP () {
  $(w).on('load', function(){
    $('.rwd002-sitemap > .rwd002-sitemap__contents:nth-of-type(2)').after(itemProf);

  });
}